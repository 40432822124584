<template>
  <div class="order-summary">
    <div class="order-container">
      <h2 class="bold-text">{{isOrderSummaryInformation.isordersummary}}</h2>
      <!-- <p class="light-text" :class="[localDisplayLang === 'ara' ? 'small-text-rtl' : 'small-text']" dir="ltr">
        {{ currentDate }}
      </p> -->
      <img :src="summaryDetails.getPoster" style="width:19vw"/>
      <div class="plan-title" v-if="orderDetails.transactionPurpose === 'SUBSCRIPTION'">
        <h3 class="bold-text">{{ $t(summaryDetails.planname) }}</h3>
        <p class="light-text small-text" v-if="!summaryDetails.rental">{{ validity }} {{isOrderSummaryInformation.isValidity }}</p>
        <p class="light-text small-text" v-else-if="summaryDetails.planinterval">{{ summaryDetails.planinterval }} {{isOrderSummaryInformation.isHoursValidity}}</p>
        <!-- <p class="light-text small-text" v-else>{{ summaryDetails.planinterval }} {{ $t("hours validity") }}</p> -->

      </div>
      <div class="plan-title" v-else-if="orderDetails.transactionPurpose === 'PURCHASE'">
        <h3 class="bold-text">{{ orderDetails.content.title }}</h3>
      </div>
      <div class="cost-details">
        <div class="rows cost light-text">
          <p>{{isOrderSummaryInformation.isPrice}}</p>
          <div class="price" dir="ltr">
            <!-- <span
              :class="[localDisplayLang === 'ara' ? 'currency_rtl' : 'currency']"
              v-if="summaryDetails.currency === 'USD'"
              >$</span
            >
            <span
              :class="[localDisplayLang === 'ara' ? 'currency_rtl' : 'currency']"
              v-if="summaryDetails.currency === 'INR'"
              >&#x20B9;</span
            > -->
            <span  :class="[localDisplayLang === 'ara' ? 'currency_rtl' : 'currency']">
              {{ getCurrency(summaryDetails.currency) }}
            </span>
            <p>{{ costPrice }}</p>
          </div>
        </div>
        <div class="rows light-text">
          <p>{{isOrderSummaryInformation.isDiscount}}</p>
          <div class="price" dir="ltr">
            <span  :class="[localDisplayLang === 'ara' ? 'currency_rtl' : 'currency']">
              {{ getCurrency(summaryDetails.currency) }}
            </span>
						<!-- <p v-if="summaryDetails.planinterval === `HALFYEAR`">{{ planAndDiscount.discount }}</p> -->
            <p >{{ discountPrice ? discountPrice : 0 }}</p>
          </div>
        </div>
        <div class="rows total-cost bold-text">
          <h4>{{ isOrderSummaryInformation.isTotalPrice}}</h4>
          <div class="price" dir="ltr">
            <span  :class="[localDisplayLang === 'ara' ? 'currency_rtl' : 'currency']">
              {{ getCurrency(summaryDetails.currency) }}
            </span>
            <!-- <span
              :class="[localDisplayLang === 'ara' ? 'currency_rtl' : 'currency']"
              v-if="summaryDetails.currency === 'USD'"
              >$</span
            >
            <span
              :class="[localDisplayLang === 'ara' ? 'currenct_rtl' : 'currency']"
              v-if="summaryDetails.currency === 'INR'"
              >&#x20B9;</span
            > -->
            <!-- <p v-if="summaryDetails.planinterval === `HALFYEAR`">{{ planAndDiscount.discountedPrice }}</p> -->
						<p >{{ totalCost }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="coupon-container" v-if="isCouponEnabled">
      <h4 class="coupon-query bold-text">{{ isCouponCode}}</h4>
      <div class="coupon-input flex-row">
        <input type="text" :placeholder="couponCodePlaceholder" id="input" v-model="couponCode" maxlength="30" />
        <Loading v-if="loader"></Loading>
        <span
          id="remove"
          :class="[localDisplayLang === 'ara' ? 'coupon-actions-ara' : 'coupon-actions']"
          v-else-if="showRemove"
          @click="removeCoupon"
          >{{ $t("Remove") }}</span
        >
        <span
          id="verify"
          :class="[localDisplayLang === 'ara' ? 'coupon-actions-ara' : 'coupon-actions']"
          v-else
          @click="verifyCoupon"
          >{{isOrderSummaryInformation.isApplyCoupon}}</span
        >
      </div>
      <div class="couponText" v-if="showRemove">
        <p>{{ applyCouponText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import "../../../../node_modules/node-snackbar/dist/snackbar.css";
import { mapGetters } from "vuex";

import { showSnackBar } from '@/utilities.js';

export default {
  props: {
    orderDetails: {
      type: Object,
    },
    couponCodeProp: {
      type: String,
    },
  },
  data() {
    return {
      currentDate: null,
      planType: "Premium",
      validity: null,
      costPrice: "",
      tax: "0",
      spPrice: "0",
      totalCost: "",
      couponCode: null,
      summaryDetails: null,
      loader: false,
      showRemove: false,
      discountPrice: null,
      couponPrecheckObj: null,
      localDisplayLang: null,
      isCouponEnabled: false,
      applyCouponText: "",
			planAndDiscount : null,
      isOrderSummaryInformation: {}
    };
  },
  watch: {
    totalCost(val) {
      if (val == 0) {
        if (this.orderDetails.transactionPurpose !== "PURCHASE") {
          // console.log("this.orderDetails", this.orderDetails);
          let payload = {
            transactionpurpose: this.orderDetails.transactionPurpose,
            planid: this.couponPrecheckObj.planid,
            availabilityid: this.couponPrecheckObj.availabilityid,
            couponid: this.couponPrecheckObj.couponid,
          };
          // console.log("payload for create subscription", payload);
          eventBus.$emit("create-free-subscription", payload);
        } else {
          let payload = {
            objectid: this.orderDetails.content.objectid,
            availabilityid: this.couponPrecheckObj.availabilityid,
            priceclassid: this.couponPrecheckObj.priceclassid,
            couponid: this.couponPrecheckObj.couponid,
          };
          eventBus.$emit("create-free-purchase", payload);
        }
      }
    },
  },
  computed: {
    ...mapGetters(["appConfig", "subscriptionList"]),
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.summaryDetails = this.orderDetails.detail;
    console.log("summary details ======", this.summaryDetails);
    this.couponCodePlaceholder = this.$t("coupon code");
    this.currentDate = this.getCurrentDate();
    this.getValidity();

    this.isCouponEnabled = this.appConfig.featureEnabled.hasCouponEnabled;
		this.planAndDiscount = this.appConfig.planDiscount;
    this.isOrderSummaryInformation.isordersummary = this.$t('order summary')
    this.isOrderSummaryInformation.isValidity = this.$t("days validity")
    this.isOrderSummaryInformation.isPrice = this.$t("price")
    this.isOrderSummaryInformation.isDiscount = this.$t("Discount")
    this.isOrderSummaryInformation.isTotalPrice = this.$t("total price")
    this.isOrderSummaryInformation.isHoursValidity = this.$t("hours validity")
    this.isOrderSummaryInformation.isCouponCode = this.$t("have a coupon code?")
    this.isOrderSummaryInformation.isApplyCoupon = this.$t("Apply")
  },
  mounted() {
    let localCouponCode = localStorage.getItem("offerCoupon");

    if(localCouponCode) {
      localCouponCode = JSON.parse(localCouponCode);
    }

    if(this.subscriptionList && this.subscriptionList.length > 0) {
      eventBus.$emit("close-payment-popup");
      this.$router.push("/");
      // showSnackBar("Already Subscribed");
    }

    if(localCouponCode && localCouponCode.planId === this.orderDetails.detail.planid) {
      this.couponCode = localCouponCode.coupon;

      if (this.couponCode) {
        this.verifyCoupon();
      }
    } else if (this.couponCodeProp) {
      this.couponCode = this.couponCodeProp;

      if (this.couponCode) {
        this.verifyCoupon();
      }
    }

    eventBus.$on("validate-coupon", (response) => {

      this.showRemove = true;
      document.getElementById("input").onkeydown = function(event) {
        event.preventDefault();
      };

      if (response.reason) {
        this.loader = false;

        if (response.errorcode === 5095) {
          eventBus.$emit("close-plan-popup", true);
        }
        this.removeCoupon();
        localStorage.removeItem("offerCoupon");

        showSnackBar(this.$t("coupon-expires"));

      } else {
        this.applyCouponText = this.getCouponText(this.appConfig.messagesList, response.nextbilling);
        this.couponPrecheckObj = response;

        this.costPrice = (response.amount / 100).toFixed(2);
        this.discountPrice = (response.discount / 100).toFixed(2);
         this.totalCost = (response.finalamount / 100).toFixed(2);
        //this.totalCost = this.costPrice - this.discountPrice;

        if (this.totalCost !== 0) {
          this.loader = false;
          let payload = {
            totalFinalCost: parseInt(this.totalCost * 100),
            couponCode: response.couponid,
            isCoupon: true,
          };

          const analyticsData = {
            nextbilling: response.nextbilling,
            couponCode: payload.couponCode,
            discountPrice: this.discountPrice,
          };

          localStorage.setItem("payment_analytics_data", JSON.stringify(analyticsData));

          console.log("THE PRECHECK --- ", analyticsData);

          localStorage.removeItem("offerCoupon");

          this.$emit("totalAfterDiscount", payload);

          if (response.finalamount == 0) {
            this.$router.push("/");
            setTimeout(() => {
              window.location.reload();
            }, 2000)
          }
        }
      }
    });
  },
  methods: {
    //coupon Validation API call
    verifyCoupon() {
      if (this.couponCode) {
        this.couponCode = this.couponCode.trim();
      }

      if (!this.couponCode) {

        showSnackBar(this.$t("Please Enter Coupon code"));

        return;
      } else {
        this.loader = true;
        // coupon validation for plans
        if (this.orderDetails.transactionPurpose !== "PURCHASE") {
          let payload = {
            transactionpurpose: this.orderDetails.transactionPurpose,
            planid: this.orderDetails.detail.planid,
            couponid: this.couponCode,
          };
          eventBus.$emit("plan-coupon-precheck", payload);
        }
        // coupon validation for purchases
        else {
          let payload = {
            objectid: this.orderDetails.content.objectid,
            availabilityid: this.orderDetails.detail.availabilityid,
            priceclassid: this.orderDetails.detail.priceclassid,
            couponid: this.couponCode,
          };
          eventBus.$emit("purchase-coupon-precheck", payload);
        }
      }
    },
    //remove coupon
    removeCoupon() {
      document.getElementById("input").onkeydown = function(event) {
        event.returnValue = true;
      };

      this.showRemove = false;
      this.applyCouponText = "";
      this.couponCode = null;
      this.costPrice = parseInt(this.summaryDetails.amount) / 100;
      this.discountPrice = 0;
      this.totalCost = parseInt(this.summaryDetails.amount) / 100;

      let payload = { totalFinalCost: this.totalCost * 100, couponCode: this.couponCode, isCoupon: false };
      this.$emit("totalAfterDiscount", payload);
    },
    getCurrentDate() {
      let d = new Date();
      let months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return d.getDate() + " " + months[d.getMonth()] + " " + d.getFullYear();
    },
    getValidity() {
      this.validity =
        this.summaryDetails.planinterval === "MONTH"
          ? "30"
          : this.summaryDetails.planinterval === "YEAR"
          ? "365"
          : this.summaryDetails.planinterval === "HALFYEAR"
          ? "183"
          : this.summaryDetails.planinterval === "WEEK"
          ? "7"
          : "90";
      this.costPrice = parseInt(this.summaryDetails.amount) / 100;
      this.totalCost = parseInt(this.summaryDetails.amount) / 100;
    },
    getCouponText(messageList, date) {
      if (this.localDisplayLang == "ara") {
        return messageList.ar.couponApplyMessage + " " + `(${this.displayDateFormat(date)})`;
      } else {
        return messageList.en.couponApplyMessage + " " + `(${this.displayDateFormat(date)})`;
      }
    },
  },
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
  beforeDestroy() {
    eventBus.$off("validate-coupon");
  },
  mixins: [Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "./OrderSummary.scss"
</style>
